import Title from "../../components/Blogs/title";
import HHAuthor from '../../assets/Images/HHAuthor.jpg'
import { useEffect } from "react";

const LynnBigFour = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Lynn.jpg')} class = "object-contain w-full"/>
                    <p class = "paragraph-four text-left text-stone-400 mt-3 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        {/* Credits: Ailene Susanto */}
                    </p>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "There are three words that everyone wants to hear when entering the corporate world. The Big Four.", 
                        "These are the words that make someone’s heart flutter, the words that signal accomplishment, the big leagues. Caught your interest? Come and meet Lynn, a previous international student who shares their experience in working at KPMG.",
                        "Koh Hui Hong", 
                        HHAuthor, 
                        "6th October 2024")}
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        There are three words that everyone always wants to hear. The words that make someone’s heart flutter. The words that signal someone has made it in their life. The words that indicate innate accomplishment.<br></br><br></br>


                        <em>The Big Four.</em><br></br><br></br>

                        This term describes the four biggest accounting firms: Deloitte, EY, PwC, and KPMG. Many university graduates look to these companies as the pinnacle when it comes to names in tax, accounting and auditing. To be a graduate trainee in the Big Four is a massive feat and many students, both domestic and international, strive to join the ranks of the world's top accounting firms.<br></br><br></br>

                        Meet Lynn.<br></br><br></br>

                        {/* Divider line */}
                        <hr style={{ borderTop: "1px solid #ccc", marginTop: "20px", marginBottom: "20px" }} /><br></br>


                        Previously an international student, Lynn was a KPMG hiree who worked in tax and business advisory and is now a finance business partner at a renewable energy development and investment firm. She had come to Australia to study before choosing to pursue a professional career in one of the Big Four firms.<br></br><br></br>

                        Not only was she an integral member of the KPMG workforce, she was also an advocate for women’s leadership within said accounting firm.<br></br><br></br>

                        “I was one of the founders of Asian Female Leaders,” she explains, describing her working life in KPMG. “It’s a safe and brave space to lift each other up and share stories.”<br></br><br></br>

                        She credits the beginning of Asian Female Leaders, more lovingly known as AFL, to an interest in listening to the experiences of women who came from Asian backgrounds, and seeing how they differed from local caucasian women.<br></br><br></br>

                        When the programme first started, it was just Lynn asking the handful of female Asian colleagues she had about their working experiences in Melbourne, making small talk and taking an interest in their lives. But when she found out that the curiosities she had were the same questions that other women of an Asian background had, she knew she had something special on her hands.<br></br><br></br>

                        The programme has now bloomed into a national initiative within KPMG that consists of over 1,000 members that hold regular events where speakers and guests can share their experiences in an empowering space.<br></br><br></br>

                        From her own personal experience alongside other women of international backgrounds, Lynn is also heavily invested in championing the hiring of international students in the local Australian workforce.<br></br><br></br>

                        “Hiring international students can allow companies to be exposed to different cultures and ways of working,” she highlights when asked about why companies should look to diversify their workforce. “It opens up the firm to a larger pool of talents and a larger pool of clients as well.”<br></br><br></br>

                        She further talks about how during her tenure at KPMG, she was able to take on more internationally-based clients because she could speak Mandarin. She cites that it was easier for her clients to converse in the same language, and that was why they had picked KPMG over other accounting firms.<br></br><br></br>

                        “Chinese-speaking clients will pick Chinese-speaking advisors,” she says. “External clients tend to pick companies that have diversity.”<br></br><br></br>

                        Lynn continues to be the flagbearer for international student hirees, bringing up the idea of gratitude that many international students hold on to for companies that gave them the chance.<br></br><br></br>

                        “International students are more likely to remember companies that were grateful to them,” she remarks positively. “International students are not new to new environments. After all, they have already moved countries for studies, so moving into a local company is not a new thing. Companies should also be mindful of the fact that international students may refer them to more talents in the future.”<br></br><br></br>

                        The main piece of advice that she wanted to hammer home for international students is the importance of networking and work experiences.<br></br><br></br>

                        “The networks that are created from your work experience can be helpful for your future.” Lynn recalls a time where a person she had talked to during her university days helped her out in getting hired. “I was talking to a lecturer who had helped me with my CV and cover letter, even though I was an international student. The lecturer had remembered me and had put my name forward to the recruiters for a job. The recruiter had then put me forward, and soon the company that hired me had changed their policy in regards to hiring international students.”<br></br><br></br>

                        She also advises that international students should look to gain work experience, regardless if it’s professional or not. The main reasoning behind this is that it highlights to employees that you as an international student have experience working in a local setting and it helps build your social and networking skills.<br></br><br></br>

                        At the end of the interview, Lynn rounds off the interview on a kind and positive note for us struggling and stressed international students.<br></br><br></br>

                        “I think it’s important to also live and enjoy life as an international student,” Lynn laughs. “Check what professional bodies are related to your degree and volunteer for these events. Don’t forget to just be a student living in a foreign country.” <br></br><br></br>
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default LynnBigFour;